<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/live/banner.png" alt="" />
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">直播、短视频融媒体财税服务</div>
        <div class="zb_content">
          <p>
            未来的时代是数字化的时代。数字化社会的到来对社会经济带来了生产方式转变变、商业模式创新、市场格局改变、就业与劳动力市场变化、数据驱动决策和社交媒体
          </p>
          <p>
            影响等多方面的改变。这些变化既带来了新的机遇和发展空间，也对企业、个人人和政府提出了新的挑战和需求。直播和短视频作为新媒体的重要形式，对信息传播、
          </p>
          <p>
            用户参与和内容定制等方面都产生了积极的影响。这种新媒体环境为用户提是供了更多选择和更好的体验，也为企业和个人提供了更广阔的传播渠道和机会。
          </p>
        </div>
        <div class="video_list">
          <div class="video_item">
            <div class="video_img">
              <!-- <div class="play_icon">
                <img src="../../assets/img/live/play.png" alt="" />
              </div> -->
              <img src="../../assets/img/live/live1.png" alt="" />
            </div>
            <div class="video_title">数电通讲座</div>
          </div>
          <div class="video_item">
            <div class="video_img">
              <!-- <div class="play_icon">
                <img src="../../assets/img/live/play.png" alt="" />
              </div> -->
              <img src="../../assets/img/live/live2.png" alt="" />
            </div>
            <div class="video_title">
              <span>《财会【2023年】7号文件开展</span>
              <span>电子凭证会计数据标准深化</span>
              <span>试点工作的通知》宣讲</span>
            </div>
          </div>
          <div class="video_item">
            <div class="video_img">
              <!-- <div class="play_icon">
                <img src="../../assets/img/live/play.png" alt="" />
              </div> -->
              <img src="../../assets/img/live/live3.png" alt="" />
            </div>
            <div class="video_title">电子函证宣讲</div>
          </div>
        </div>

        <div class="video_btn" @click="handleApply(1)">了解更多</div>
      </div>
    </div>
    <div class="container">
      <div class="box">
        <div class="one_title">政策信息</div>
        <div class="zc_box">
          <div class="left_zc_img">
            <img src="../../assets/img/live/zcxx.png" alt="" />
          </div>
          <div class="right_zc_list">
            <div class="zc_item">
              <div class="zc_item_left">
                <span class="cir"></span>
                <span class="zc_title">新金税系统上线重点稽查行业</span>
              </div>
              <span class="zc_time">2024-01-01</span>
            </div>
            <div class="zc_item">
              <div class="zc_item_left">
                <span class="cir"></span>
                <span class="zc_title">财务管理对企业发展的重大意义</span>
              </div>
              <span class="zc_time">2024-01-01</span>
            </div>
            <div class="zc_item">
              <div class="zc_item_left">
                <span class="cir"></span>
                <span class="zc_title">企业存在的高风险财税问题</span>
              </div>
              <span class="zc_time">2024-01-01</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">培训课程</div>
        <div class="zb_content">
          <p>
            提供涉及财税、法律、股权、营销、知识产权和专业技能等订单式培训课程
          </p>
          <p>推动政策应享尽享、培训随点随学</p>
        </div>
        <div class="kc_list">
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc1.png" alt="" />
            </div>
            <div class="kc_title">财税课程</div>
          </div>
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc2.png" alt="" />
            </div>
            <div class="kc_title">法律课程</div>
          </div>
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc3.png" alt="" />
            </div>
            <div class="kc_title">股权课程</div>
          </div>
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc4.png" alt="" />
            </div>
            <div class="kc_title">营销课程</div>
          </div>
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc5.png" alt="" />
            </div>
            <div class="kc_title">知识产权课程</div>
          </div>
          <div class="kc_item">
            <div class="kc_img">
              <img src="../../assets/img/live/pxkc6.png" alt="" />
            </div>
            <div class="kc_title">专业技能课程</div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="one_title">线下活动</div>
        <div class="hd_list">
          <div class="hd_item">
            <img src="../../assets/img/live/xxhd1.png" alt="" />
          </div>
          <div class="hd_item">
            <img src="../../assets/img/live/xxhd2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="one_title">行业解决方案</div>
        <div class="fa_list">
          <div class="fa_item">
            <div class="fa_img">
              <img src="../../assets/img/live/fa1.png" alt="" />
            </div>
            <div class="fa_title">财税管理</div>
          </div>
          <div class="fa_item">
            <div class="fa_img">
              <img src="../../assets/img/live/fa2.png" alt="" />
            </div>
            <div class="fa_title">发展战略</div>
          </div>
          <div class="fa_item">
            <div class="fa_img">
              <img src="../../assets/img/live/fa3.png" alt="" />
            </div>
            <div class="fa_title">生产经营</div>
          </div>
          <div class="fa_item">
            <div class="fa_img">
              <img src="../../assets/img/live/fa4.png" alt="" />
            </div>
            <div class="fa_title">市场营销</div>
          </div>
          <div class="fa_item">
            <div class="fa_img">
              <img src="../../assets/img/live/fa5.png" alt="" />
            </div>
            <div class="fa_title">品牌创建</div>
          </div>
        </div>
        <div class="video_btn" @click="handleApply(2)">了解更多</div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 体验信息提交
    handleApply(num) {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "27";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.two_title {
  font-size: 18px;
  color: #949494;
  text-align: center;
  margin-top: 10px;
}

.zb_content {
  font-size: 16px;
  color: black;
  margin-top: 50px;
}

.zb_content p {
  text-align: center;
  line-height: 27px;
}

.video_list {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.video_item {
  width: 376px;
}

.video_img {
  position: relative;
  width: 100%;
  height: 200px;
}

.video_img img {
  display: block;
  width: 100%;
}

.play_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
}

.play_icon img {
  display: block;
  width: 100%;
}

.video_title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
}

.video_title span {
  display: block;
  width: 100%;
  text-align: center;
}

.video_btn {
  width: 205px;
  height: 50px;
  background: #055afe;
  border-radius: 29px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  margin: 0 auto;
  margin-top: 60px;
  font-size: 20px;
  font-weight: 600;
  color: #fffefe;
  cursor: pointer;
}

.zc_box {
  margin-top: 50px;
  display: flex;
}

.left_zc_img {
  width: 433px;
  height: 200px;
}

.left_zc_img img {
  display: block;
  width: 100%;
}

.right_zc_list {
  flex: 1;
  background: #f5f5f5;
  padding: 40px;
}

.zc_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #787575;
  margin-bottom: 20px;
}

.cir {
  width: 5px;
  height: 5px;
  background: #055afe;
  border-radius: 50%;
  margin-right: 8px;
}

.zc_time {
  font-size: 12px;
  color: #000000;
  opacity: 0.8;
}

.zc_item_left {
  display: flex;
  align-items: center;
}

.kc_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  border-bottom: 5px solid #055afe;
}

.kc_item {
  width: 16.66%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kc_img {
  width: 50px;
  height: 48px;
}

.kc_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.kc_title {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
  margin-top: 20px;
}

.hd_list {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.hd_item {
  width: 320px;
  height: 188px;
  margin: 0 30px;
}

.hd_item img {
  display: block;
  width: 100%;
}

.fa_list {
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fa_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 50px;
  box-shadow: -1px 1px 9px 0px rgba(120, 117, 117, 0.2);
  border-radius: 8px;
}

.fa_img {
  width: 50px;
  height: 55px;
}

.fa_img img {
  display: block;
  width: 100%;
}

.fa_title {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
}

.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
